// state
export const state = () => ({
    localeVariables: [],
    industries: [],
    priceTiers: [],
    creditPacks: [],
    licences: [],
    promotions: []
})

// getters
export const getters = {
    localeVariables: state => state.localeVariables,
    industries: state => state.industries,
    priceTiers: state => state.priceTiers,
    creditPacks: state => state.creditPacks,
    licences: state => state.licences,
    promotions: state => state.promotions,
    maxPromotion: state => () => {
        if (state.promotions.length > 0) {
            return state.promotions.reduce((max, obj) => (max.discount_pcnt > obj.discount_pcnt ? max : obj), state.promotions[0])
        }

        return null
    }
}

// mutations
export const mutations = {
    SET_LOCALE_VARIABLES (state, localeVariables) {
        state.localeVariables = localeVariables
    },
    SET_INDUSTRIES (state, industries) {
        state.industries = industries
    },
    SET_PRICE_TIERS (state, priceTiers) {
        state.priceTiers = priceTiers
    },
    SET_CREDIT_PACKS (state, creditPacks) {
        state.creditPacks = creditPacks
    },
    SET_LICENCES (state, licences) {
        state.licences = licences
    },
    SET_PROMOTIONS (state, promotions) {
        state.promotions = promotions
    }
}

// actions
export const actions = {}
