import Cookies from 'js-cookie'
import axios from 'axios'
import { cookieFromRequest } from '~/utils'

export const actions = {
    async nuxtServerInit ({ commit }, { req }) {
        const token = cookieFromRequest(req, 'token')
        if (token) {
            commit('auth/SET_TOKEN', token)
        }

        const locale = cookieFromRequest(req, 'locale')
        if (locale) {
            commit('lang/SET_LOCALE', { locale })
        }

        const localePromise = axios.get('/locale').then(rsp => rsp.data)
        const industriesPromise = axios.get('/industries?format=select').then(rsp => rsp.data.data)
        const priceTiersPromise = axios.get('/price-tiers').then(rsp => rsp.data)
        const creditPacksPromise = axios.get('/credit-packs').then(rsp => rsp.data)
        const licencesPromise = axios.get('/licences').then(rsp => rsp.data)
        const promotionsPromise = axios.get('/promotions').then(rsp => rsp.data)

        await Promise.all([
            localePromise,
            industriesPromise,
            priceTiersPromise,
            creditPacksPromise,
            licencesPromise,
            promotionsPromise
        ])
            .then(([
                localeData,
                industriesData,
                priceTiersData,
                creditPacksData,
                licencesData,
                promotionsData
            ]) => {
                commit('data/SET_LOCALE_VARIABLES', localeData)
                commit('data/SET_INDUSTRIES', industriesData)
                commit('data/SET_PRICE_TIERS', priceTiersData)
                commit('data/SET_CREDIT_PACKS', creditPacksData)
                commit('data/SET_LICENCES', licencesData)
                commit('data/SET_PROMOTIONS', promotionsData)
            })
            .catch((error) => {
                console.error('An error occurred:', error)
            })
    },

    nuxtClientInit ({
        commit,
        getters
    }) {
        const token = Cookies.get('token')
        if (token && !getters['auth/token']) {
            commit('auth/SET_TOKEN', token)
        }

        const locale = Cookies.get('locale')
        if (locale && !getters['lang/locale']) {
            commit('lang/SET_LOCALE', { locale })
        }
    }
}
